.icon {
  vertical-align: middle;

  &.inactive {
    color: grey;
  }

  &.refunded {
    color: $white;
  }

  .table & {
    margin-right: 10px;
  }
}

p .icon {
  display: inline;
}
