@import 'colors';

.mat-mdc-form-field {
  width: 100%;
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mat-input-text, $valhalla);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--filled .mdc-line-ripple:after {
  border-bottom-color: var(--mat-input-text, $valhalla) !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: var(--mat-input-checkbox-ripple, $selective-yellow);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: rgba(98, 98, 98) !important;
}
