/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

// Fonts
@import '../fonts/satoshi/satoshi';

@import 'colors';
@import 'iconfont';

@import 'mixins';
@import 'bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

// Optional
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/alert';
// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';

@import 'material';
@import 'page';
@import 'badge';
@import 'icons';
@import 'overlay';
@import 'mark';
@import 'upload';

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat.all-component-typographies();

@include mat.core();

// include the custom theme components into a theme object
$bv-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $bv-palette-primary,
      accent: $bv-palette-accent,
    ),
    typography: mat.m2-define-typography-config('Satoshi'),
    density: 0,
  )
);

@include mat.all-component-themes($bv-theme);

@import 'mat-form-field';
@import 'mat-card';
@import 'mat-link';
@import 'mat-chip';
@import 'mat-tab';
@import 'mat-button';
@import 'mat-table';
@import 'mat-dialog';
@import 'mat-snackbar';
@import 'mat-toggle';
@import 'mat-progess-bar';
@import 'mat-stepper';
@import 'mat-calendar';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Satoshi', 'Helvetica Neue', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}
