.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background: var(--stepper-background, $valhalla);
  color: var(--stepper-color, $white);
}
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: none;
}
