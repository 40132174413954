@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: normal;
  src:
    local('Satoshi Regular'),
    url('/assets/fonts/satoshi/Satoshi-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: normal;
  src:
    local('Satoshi Italic'),
    url('/assets/fonts/satoshi/Satoshi-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 300;
  src:
    local('Satoshi Light'),
    url('/assets/fonts/satoshi/Satoshi-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 300;
  src:
    local('Satoshi Light Italic'),
    url('/assets/fonts/satoshi/Satoshi-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  src:
    local('Satoshi Medium'),
    url('/assets/fonts/satoshi/Satoshi-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 500;
  src:
    local('Satoshi Medium Italic'),
    url('/assets/fonts/satoshi/Satoshi-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  src:
    local('Satoshi Bold'),
    url('/assets/fonts/satoshi/Satoshi-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 700;
  src:
    local('Satoshi Bold Italic'),
    url('/assets/fonts/satoshi/Satoshi-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  src:
    local('Satoshi Black'),
    url('/assets/fonts/satoshi/Satoshi-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 900;
  src:
    local('Satoshi Black Italic'),
    url('/assets/fonts/satoshi/Satoshi-BlackItalic.woff') format('woff');
  font-display: swap;
}
