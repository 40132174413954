.badge {
  font-size: 15px;

  &.activated {
    background: #{$hippie-green};
    color: #{$white};
  }

  &.paid {
    background: #{$royal-blue};
    color: #{$white};
  }

  &.disabled {
    background: #{$neon-carrot};
    color: #{$white};
  }

  &.deleted {
    background: #{$sunset-orange};
    color: #{$white};
  }

  &.other {
    background: #5737f7;
  }

  &.new_lead {
    background: #1e7fcb;
  }

  &.in_progress {
    background: #4f8c4b;
  }

  &.planning {
    background: #f1c40f;
  }

  &.funeral {
    background: #0065b6;
  }

  &.marbrerie {
    background: #039a9a;
  }

  &.purchase {
    background: #000000;
  }

  &.wrong_number {
    background: #f44336;
  }

  &.outside_sector {
    background: #5f6a6a;
  }

  &.competitor_search {
    background: #ff9800;
  }

  &.deceased_search {
    background: #d80065;
  }

  &.billing {
    background: #6d6875;
  }
}
