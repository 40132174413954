@import 'colors';

.mat-calendar-body-selected {
  background: rgba(98, 98, 98);
}
.mat-calendar-body-in-range::before {
  background: rgba(0, 0, 0, 0.2);
}

.mat-datepicker-toggle-active {
  color: var(--mat-picker-selected-background, $valhalla);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: rgba(0, 0, 0, 0.4);
}
