.upload {
  background-color: $wild-sand;
  padding: 50px 20px;
  outline: 2px dashed $mercury;
  outline-offset: -10px;
  -webkit-transition:
    outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition:
    outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  border-radius: 0.25rem;

  &__container {
    position: relative;
    background: $wild-sand;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &.is-invalid {
    border: 1px solid red;
  }

  &__file {
    opacity: 0;
    display: none;

    & + label {
      width: 100%;
      margin: 2px auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: block;
      overflow: hidden;
      text-align: center;
    }
  }

  &__remove {
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-block;
    text-align: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border: 1px solid red;
    background: red;
    border-radius: 50%;
    font-size: 13px;
    line-height: 22px;
    color: $white;
    font-weight: 700;
  }

  &__preview {
    &-text {
      display: inline-block;
      position: relative;
      padding-right: 30px;

      .upload__remove {
        top: 0px;
        right: 0px;
      }
    }
  }

  &.is-dragover {
    outline-offset: -20px;
    outline-color: #c8dadf;
    background-color: #fff;
  }

  &.is-uploading .upload__input {
    visibility: hidden;
  }
  &.is-uploading .upload__uploading {
    display: block;
  }

  &.has-advanced-upload .upload__dragndrop {
    display: inline;
  }

  &__icon {
    width: 100%;
    height: 80px;
    fill: $black;
    display: block;
    margin-bottom: 40px;
  }
}
