@use '@angular/material' as mat;
@import 'colors';
@import 'material';

.mat-mdc-table {
  .mat-mdc-header-cell,
  .mat-mdc-sort-header-content {
    color: var(--mat-table-heading, $valhalla);
    font-weight: 500;
  }

  @media screen and (max-width: 991px) {
    .mat-mdc-footer-cell {
      display: block;
      width: 100%;
      padding: 0 16px;
      margin: 16px 0;
      border: 0 none;
    }

    .mat-mdc-footer-row {
      display: block;
      margin-top: 24px;
      overflow: hidden;
      height: auto;
      position: relative;
      clear: both;
    }

    .has_label_on_mobile {
      display: flex;
      height: auto;
      margin: 0px;

      &:before {
        content: attr(data-label);
        font-weight: 700;
        margin-right: 4px;
      }
    }
  }
}
