.link {
  color: var(--bs-link-color);
}
.link:hover {
  color: var(--bs-link-hover-color);
}
a {
  color: var(--bs-link-color);

  &:hover {
    color: var(--bs-link-hover-color);
  }
}

a:not([href]) {
  color: var(--bs-link-color);
}
