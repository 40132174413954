/* removes any indents and bullet points from lists */
@mixin list-bare() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin invert-links() {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
