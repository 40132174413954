@use '@angular/material' as mat;
@import 'material';

.mat-mdc-card-header {
  justify-content: space-between;
  align-items: center;

  .mat-card-header-text {
    margin: 0px 16px 0px 0px;
  }
}

.mat-mdc-dialog-container,
.mat-mdc-card {
  .mat-mdc-card-title {
    font-size: 18px;
    color: var(--mat-card-title, $black);
  }
  .mat-mdc-card-subtitle {
    color: $black;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.mat-mdc-card {
  .mat-mdc-card {
    @include mat.elevation(4);

    .mat-mdc-card {
      @include mat.elevation(6);
    }
  }
}
