.overlay {
  transition: all ease 0.2s;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 301;
  display: none;

  &--active {
    display: block;
  }
}
