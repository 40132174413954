$__iconfont__data: map-merge(
  if(global_variable_exists('__iconfont__data'), $__iconfont__data, ()),
  (
    'iconfont': (
      'active': '\ea01',
      'administrative-fine': '\ea02',
      'agency': '\ea03',
      'arbres-hommages': '\ea04',
      'arbres': '\ea05',
      'arrow-left': '\ea06',
      'arrow-right': '\ea07',
      'ask-review': '\ea08',
      'bag': '\ea09',
      'ballon-de-parole': '\ea0a',
      'black-wrench': '\ea0b',
      'boutique-fleurs': '\ea0c',
      'boutique': '\ea0d',
      'calendar': '\ea0e',
      'call-missed': '\ea0f',
      'call-origin': '\ea10',
      'call-unique': '\ea11',
      'call': '\ea12',
      'caret-right': '\ea13',
      'caret': '\ea14',
      'category': '\ea15',
      'cemetery': '\ea16',
      'clock': '\ea17',
      'close': '\ea18',
      'collaboration': '\ea19',
      'condolence-count': '\ea1a',
      'condolence-death-notice-view': '\ea1b',
      'condolence-income': '\ea1c',
      'contact': '\ea1d',
      'copy': '\ea1e',
      'crematorium': '\ea1f',
      'cult': '\ea20',
      'customer': '\ea21',
      'customize': '\ea22',
      'delete': '\ea23',
      'deleted': '\ea24',
      'delivered': '\ea25',
      'digital-performance': '\ea26',
      'digital-performances-campaign': '\ea27',
      'digital-performances-click': '\ea28',
      'digital-performances-cout': '\ea29',
      'digital-performances-cpc': '\ea2a',
      'digital-performances-rate-click': '\ea2b',
      'disabled': '\ea2c',
      'download': '\ea2d',
      'edit': '\ea2e',
      'email': '\ea2f',
      'entretien': '\ea30',
      'estimate-by-type': '\ea31',
      'estimate-created': '\ea32',
      'estimate-realization-rate': '\ea33',
      'facebook': '\ea34',
      'family-space-cagnotte-collected': '\ea35',
      'family-space-cagnotte-created': '\ea36',
      'family-space-connection': '\ea37',
      'family-space-created': '\ea38',
      'family-space-service-enabled': '\ea39',
      'family-space': '\ea3a',
      'favorite': '\ea3b',
      'fleurs': '\ea3c',
      'gear': '\ea3d',
      'google-ads-conversions-all': '\ea3e',
      'google-ads-conversions-cout': '\ea3f',
      'google-ads-conversions-other': '\ea40',
      'google-ads-conversions-value': '\ea41',
      'google-ads-conversions': '\ea42',
      'google-ads-rate-list-top': '\ea43',
      'google-ads-rate-lost': '\ea44',
      'google-ads-visibility-campagn': '\ea45',
      'google-ads-visibility-top1': '\ea46',
      'google-ads-visibility-top4': '\ea47',
      'google-ads-visiblity-campaign': '\ea48',
      'google-adwords': '\ea49',
      'google-location-click': '\ea4a',
      'google-location-itinerary': '\ea4b',
      'google-location-top-search': '\ea4c',
      'google-location-user-search': '\ea4d',
      'google-review-notation': '\ea4e',
      'google-review-timing': '\ea4f',
      'google-review': '\ea50',
      'graphiques': '\ea51',
      'heart': '\ea52',
      'home': '\ea53',
      'hospital-clinical': '\ea54',
      'inactive': '\ea55',
      'info': '\ea56',
      'invoice': '\ea57',
      'label': '\ea58',
      'locate-place': '\ea59',
      'mail': '\ea5a',
      'menu-slide': '\ea5b',
      'menu': '\ea5c',
      'new': '\ea5d',
      'order-average-cart': '\ea5e',
      'order-comission': '\ea5f',
      'order-top': '\ea60',
      'order-transaction': '\ea61',
      'order-turnover': '\ea62',
      'order': '\ea63',
      'other-places': '\ea64',
      'package': '\ea65',
      'performances-google': '\ea66',
      'phone': '\ea67',
      'places': '\ea68',
      'print': '\ea69',
      'printing': '\ea6a',
      'product': '\ea6b',
      'profils': '\ea6c',
      'refunded': '\ea6d',
      'resting-place': '\ea6e',
      'retirement-home': '\ea6f',
      'search': '\ea70',
      'shop': '\ea71',
      'star-outline': '\ea72',
      'star': '\ea73',
      'statistiques': '\ea74',
      'stats': '\ea75',
      'time-delay': '\ea76',
      'timer': '\ea77',
      'top': '\ea78',
      'user-informations': '\ea79',
      'user': '\ea7a',
      'users': '\ea7b',
      'view': '\ea7c',
      'website-page-views': '\ea7d',
      'website-rate-bounce': '\ea7e',
      'website-session': '\ea7f',
      'website-source': '\ea80',
      'website-top-page-views': '\ea81',
      'website-user-devices': '\ea82',
      'website': '\ea83',
      'writing': '\ea84',
    ),
  )
);

$create-font-face: true !default; // should the @font-face tag get created?

// should there be a custom class for each icon? will be .filename
$create-icon-classes: true !default;

// what is the common class name that icons share? in this case icons need to have .icon.filename in their classes
// this requires you to have 2 classes on each icon html element, but reduced redeclaration of the font family
// for each icon
$icon-common-class: 'icon' !default;

// if you whish to prefix your filenames, here you can do so.
// if this string stays empty, your classes will use the filename, for example
// an icon called star.svg will result in a class called .star
// if you use the prefix to be 'icon-' it would result in .icon-star
$icon-prefix: '' !default;

// helper function to get the correct font group
@function iconfont-group($group: null) {
  @if (null == $group) {
    $group: nth(map-keys($__iconfont__data), 1);
  }
  @if (false == map-has-key($__iconfont__data, $group)) {
    @warn 'Undefined Iconfont Family!';
    @return ();
  }
  @return map-get($__iconfont__data, $group);
}

// helper function to get the correct icon of a group
@function iconfont-item($name) {
  $slash: str-index($name, '/');
  $group: null;
  @if ($slash) {
    $group: str-slice($name, 0, $slash - 1);
    $name: str-slice($name, $slash + 1);
  } @else {
    $group: nth(map-keys($__iconfont__data), 1);
  }
  $group: iconfont-group($group);
  @if (false == map-has-key($group, $name)) {
    @warn 'Undefined Iconfont Glyph!';
    @return '';
  }
  @return map-get($group, $name);
}

// complete mixing to include the icon
// usage:
// .my_icon{ @include iconfont('star') }
@mixin iconfont($icon) {
  $slash: str-index($icon, '/');
  $group: null;
  @if ($slash) {
    $group: str-slice($icon, 0, $slash - 1);
  } @else {
    $group: nth(map-keys($__iconfont__data), 1);
  }
  &:before {
    font-family: $group;
    font-style: normal;
    font-weight: 400;
    content: iconfont-item($icon);
  }
}

// creates the font face tag if the variable is set to true (default)
@if $create-font-face == true {
  @font-face {
    font-family: 'iconfont';
    src: url('../fonts/iconfont.eot'); /* IE9 Compat Modes */
    src:
      url('../fonts/iconfont.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('../fonts/iconfont.woff') format('woff'),
      /* Pretty Modern Browsers */ url('../fonts/iconfont.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('../fonts/iconfont.svg') format('svg'); /* Legacy iOS */
  }
}

// creates icon classes for each individual loaded svg (default)
@if $create-icon-classes == true {
  .#{$icon-common-class} {
    font-style: normal;
    font-weight: 400;

    @each $icon, $content in map-get($__iconfont__data, 'iconfont') {
      &.#{$icon-prefix}#{$icon}:before {
        font-family: 'iconfont';
        content: iconfont-item('iconfont/#{$icon}');
      }
    }
  }
}
