.mdc-snackbar {
  --mat-mdc-snack-bar-button-color: #ffffff;
}

.mat-mdc-snack-bar-container {
  &.error .mdc-snackbar__surface {
    background: white;
  }
  &.error .mdc-snackbar__label,
  &.error .mdc-button__label {
    color: red !important;
  }
}
