.page {
  @include make-row();

  &__content {
    @include make-col-ready();
    @include make-col(12);
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    color: var(--page-title, $valhalla);
  }

  &__subTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    color: var(--page-title, $valhalla);
  }

  &__actions {
    color: $valhalla;
  }
}
