@import 'colors';

.mat-mdc-chip {
  &.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
    &.activated {
      --mdc-chip-elevated-disabled-container-color: #{$hippie-green};
      --mdc-chip-disabled-label-text-color: #{$white};
      opacity: 1;
    }

    &.paid {
      --mdc-chip-elevated-disabled-container-color: #{$royal-blue};
      --mdc-chip-disabled-label-text-color: #{$white};
      opacity: 1;
    }

    &.disabled {
      --mdc-chip-elevated-disabled-container-color: #{$neon-carrot};
      --mdc-chip-disabled-label-text-color: #{$white};
      opacity: 1;
    }

    &.deleted {
      --mdc-chip-elevated-disabled-container-color: #{$sunset-orange};
      --mdc-chip-disabled-label-text-color: #{$white};
      opacity: 1;
    }
  }
}
