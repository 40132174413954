//Themes
$mischka: #d8dbe2;
$casper: #a9bcd0;
$hippie-blue: #58a4b0;
$oxford-blue: #373f51;
$shark: #1b1b1e;
$trout: #495057;
$buddha-gold: #ff8f00;
$azure-radiance: #0087ff;
$nobel: #b3b3b3;
$galery: #ededed;

$royal-blue: #3871e0;
$shark: #212529;
$selago: #e4edfb;

//Others
$wild-sand: #f5f5f5;
$mercury: #e2e2e2;
$white: #ffffff;
$amber: #f4c100;

$hippie-green: #4f8c4b;
$neon-carrot: #ff8f35;
$sunset-orange: #ff4b4b;
$ava: #5e3984;
$selective-yellow: #fab600;
$valhalla: #27174f;
$silver-chalice: #a3a3a3;
$thunder: #231f20;
$viking: #5eafde;
$purple-heart: #6323c8;
$dusty-gray: #959595;
