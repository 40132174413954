@import 'colors';

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.9);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.9);

  .mdc-dialog__title {
    font-size: 20px;
    color: var(--mat-dialog-title, $valhalla) !important;
  }
}

.cdk-overlay-pane.mat-dialog-container-close {
  position: relative !important;
}
.mat-dialog-close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 200;
}

.dialog-without-background .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background: none;
  box-shadow: none;
}
