/*.mat-mdc-raised-button:not(:disabled) {
  color: var(--button-secondary-color, var(--mdc-protected-button-label-text-color, inherit));
  background-color: var(--button-secondary-background, var(--mdc-protected-button-container-color, transparent));
}*/

.mat-accent {
  &.mat-mdc-unelevated-button:not(:disabled) {
    color: var(--button-primary-color, var(--mdc-filled-button-label-text-color, inherit));
    background-color: var(--button-primary-background, var(--mdc-filled-button-container-color, transparent));
  }

  &.mat-mdc-raised-button:not(:disabled) {
    color: var(--button-primary-color, var(--mdc-protected-button-label-text-color, inherit));
    background-color: var(--button-primary-background, var(--mdc-protected-button-container-color, transparent));
  }
}
.mat-primary {
  &.mat-mdc-raised-button:not(:disabled) {
    color: var(--button-secondary-color, var(--mdc-protected-button-label-text-color, inherit));
    background-color: var(--button-secondary-background, var(--mdc-protected-button-container-color, transparent));
  }
  &.mat-mdc-unelevated-button:not(:disabled) {
    color: var(--button-secondary-color, var(--mdc-protected-button-label-text-color, inherit));
    background-color: var(--button-secondary-background, var(--mdc-protected-button-container-color, transparent));
  }
  &.mat-mdc-outlined-button:not(:disabled) {
    color: var(--button-secondary-color, var(--mdc-outlined-button-label-text-color, inherit));
  }
  &.mat-mdc-raised-button {
    &.ia-tool {
      .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
        --mdc-circular-progress-active-indicator-color: rgba(255, 255, 255);
      }

      color: #ffffff;
      background-color: $valhalla;
      width: 100%;
      height: 56px;

      mat-icon {
        height: 17px;
        width: 17px;
        font-size: 14px;
        line-height: 14px;
        color: $white;
      }

      &[disabled] {
        background: $nobel;
        color: $black;

        .mat-icon {
          color: $black;
        }
      }
    }
  }
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #{$black};
}

.back-link {
  font-size: 14px;
  mat-icon {
    font-size: 10px;
    line-height: 16px;
    height: 17px;
    width: 20px;
  }
}
