/*.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
  background: var(--mat-toggle, var(--mdc-switch-selected-handle-color, var(--mdc-theme-primary)))!important;
}
.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after{
  background: var(--mat-toggle, var(--mdc-switch-selected-track-color))!important;
}
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after{
  background: var(--mat-toggle, var(--mdc-switch-selected-handle-color, var(--mdc-theme-primary)))!important;
  opacity: 0.6;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before{
  background: var(--mat-toggle, var(--mdc-switch-selected-handle-color, var(--mdc-theme-primary)))!important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before,
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after{
  background: var(--mat-toggle, var(--mdc-switch-selected-pressed-state-layer-color, var(--mdc-theme-primary)))!important;
}
*/
